<template>
  <div
    :class="['c-radio', description ? 'top' : alignV, hasContent ? 'top' : '']"
  >
    <input
      :id="id"
      v-model="getModel"
      :type="type"
      :value="value"
      :disabled="disabled"
    >
    <label
      :for="id"
      :class="{ 'is-cancel': isCancel }"
    >
      <div class="body-content">
        <span :class="bold ? 'bold-font' : ''">{{ label }}</span>
        <template v-if="description">
          <p
            v-line-clamp="count"
            class="small-text mb-0"
            style="word-break: break-word !important"
          >
            {{ description }}
          </p>
          <template v-show="count > 1">
            <a
              href="javascript:void(0)"
              class="d-block show-more mb-4 text-primary"
              @click="toggle"
            >
              <template v-if="count > 1">
                <u>More Details</u>
              </template>
              <template v-else>
                <u>Less Details</u>
              </template>
            </a>
          </template>
        </template>
        <div
          v-if="treatment"
          v-show="!isZeroPrice"
        >
          <p class="small-text mb-0 bold-font">{{ treatmentText }}</p>
        </div>
        <div v-else>
          <p
            v-if="pricing"
            class="mb-0"
          >{{ pricing }}</p>
          <slot></slot>
        </div>
      </div>
    </label>
    <div
      v-if="recommended"
      class="recommended"
    >
      {{ recommendedText }}
    </div>
    <slot name="image"></slot>
  </div>
</template>
<script>
export default {
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    type: {
      type: String,
    },
    checked: {
      type: [Array, Boolean, String, Object],
      default: false,
    },
    value: {
      default: null,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    pricing: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    bold: {
      type: Boolean,
    },
    alignV: {
      type: String,
      default: 'center',
      validator(x) {
        return ['top', 'center', 'bottom'].includes(x);
      },
    },
    clampText: {
      type: Number,
      default: 2,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    recommended: {
      type: Boolean,
      default: false,
    },
    recommendedText: {
      type: String,
      default: 'Recommended',
    },
    treatment: {
      type: Boolean,
      default: false,
    },
    treatmentText: {
      type: String,
      default: '',
    },
    isCancel: {
      type: Boolean,
      default: false,
    },
    hasContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      count: this.$props.clampText,
    };
  },
  computed: {
    showDetails: {
      get() {
        return this.count;
      },
      set(val) {
        this.$props.clampText = val;
      },
    },
    getModel: {
      get() {
        return this.checked;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    isZeroPrice() {
      if (this.treatment) {
        return this.treatmentText.includes('$0');
      } else {
        return false;
      }
    },
  },
  methods: {
    toggle() {
      if (this.count == 2) this.count = 0;
      else this.count = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
$class: ".c-radio";
$radius: 8px;

$default-border-color: #e0e0e0;
$active-border-color: $info;

.pharma-selection,
.shipping-price {
  .show-more {
    display: none !important;
  }
}

.list-unstyled {
  li {
    display: inline-block;
    margin-right: 5px;

    &.text-muted {
      color: #bdbdbd;
    }
  }
}

.show-more {
  position: relative;
  z-index: 2;
  font-size: 12px;

  @include media-breakpoint-up(xxl) {
    font-size: 12px * $scale-font;
  }
}

.shipping-price,
.treatment-type {
  $radius: 16px;

  #{$class} {
    border-radius: $radius;

    @include media-breakpoint-up(xxl) {
      border-radius: $radius * $scale-font;
    }

    input + label:after,
    input + label:before {
      border-radius: $radius;

      @include media-breakpoint-up(xxl) {
        border-radius: $radius * $scale-font;
      }
    }
  }
}

.shipping-price {
  #{$class} {
    .body-content {
      span {
        font-family: $bold-font;
      }
    }
  }
}

#{$class} {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 1.2rem;
  min-height: 50px;
  margin-bottom: 15px;
  border-radius: $radius;
  overflow: hidden;

  @include media-breakpoint-up(xxl) {
    padding: 15px * $scale-font 1.2rem * $scale-font;
    border-radius: $radius * $scale-font;
    margin-bottom: 15px * $scale-font;
  }

  &.top {
    align-items: flex-start;

    input + label {
      &:before {
        align-self: flex-start;
      }
    }
  }

  &.center {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;

    input + label {
      &:before {
        align-self: flex-end;
      }
    }
  }

  &.no-option {
    $radius: 30px;

    input + label:after,
    input + label:before {
      border-radius: $radius;
    }
  }

  input {
    position: absolute;
    left: -9999px;

    + label {
      flex: 1 0 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 0;

      &.is-cancel {
        color: white;
      }

      &.is-cancel:after {
        border: 2px solid #cdfcb1;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid $default-border-color;
        border-radius: $radius;

        @include media-breakpoint-up(xxl) {
          border-radius: $radius * $scale-font;
        }
      }

      &.is-cancel::before {
        background-color: #cdfcb1;
      }

      &::before {
        content: "";
        display: inline-flex;
        flex: 1 150vw; // fix display in mobile devices
        float: left;
        top: auto;
        left: 15px;
        width: 20px;
        height: 20px;
        max-width: 20px;
        margin-left: 4px;
        border-radius: 30px;
        background-color: $default-border-color;
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center;

        @include media-breakpoint-up(xxl) {
          border-radius: $radius * $scale-font;
          width: 20px * $scale-font;
          height: 20px * $scale-font;
          max-width: 20px * $scale-font;
          margin-left: 4px * $scale-font;
        }
      }

      .body-content {
        margin-left: 1rem;

        @include media-breakpoint-up(xxl) {
          margin-left: 1rem * $scale-font;
        }
      }

      span {
        display: block;
        font-size: 1rem;

        @include media-breakpoint-up(xxl) {
          font-size: 1rem * $scale-font;
        }
      }

      small {
        flex: 1;
        min-width: 0; // fix text-truncate
        display: block;
        white-space: break-spaces;
        word-break: break-word !important;
      }
    }

    &:checked {
      + label:before {
        background-color: $active-border-color;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M9 21.035l-9-8.638 2.791-2.87 6.156 5.874 12.21-12.436 2.843 2.817z'/%3e%3c/svg%3e");
      }
    }
  }

  input + label:hover:after,
  input:checked + label:after {
    border-color: $active-border-color;
    border-width: 3px;
  }

  .img-holder {
    width: 70px;
    height: 55px;

    @include media-breakpoint-up(xxl) {
      width: 70px * $scale-font;
      height: 55px * $scale-font;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.addons.c-radio {
  border-radius: $radius - 10;
}

.addons.c-radio input + label:before,
.addons.c-radio input + label:after {
  border-radius: $radius - 10;
}

.recommended {
  position: absolute;
  top: 10px;
  padding: 5px 10px;
  background: #ff8d40;
  right: 0;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 10px;
}

@media (max-width: 320px) {
  .c-radio {
    overflow: initial;

    .recommended {
      top: -12px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.c-radio {
  @include media-breakpoint-down(sm) {
    min-height: auto;
    padding: 12px;
  }
}
</style>
